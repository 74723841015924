<template>
  <div>
    <label v-if="label" class="block text-sm font-medium text-gray-700" :class="labelClass">
      <T :keyName="label" />
    </label>
    <div class="">
      <input
        :type="getInputType"
        class="rounded-full shadow-sm focus:border-gray-500 focus:ring-gray-500 focus:ring-0 [-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
        :class="computedInputClass"
        :placeholder="placeholder"
        :value="formattedValue"
        @input="onInput"
        @change="emitChange"
        :readonly="readonly"
      />
      <div v-if="trailingText" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <span class="text-gray-500 sm:text-sm">{{ trailingText }}</span>
      </div>
    </div>
    <p v-if="error" class="error-message">{{ error }}</p>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";

const props = defineProps([
  "leadingText",
  "trailingText",
  "placeholder",
  "color",
  "small",
  "modelValue",
  "inputValue",
  "inputClass",
  "label",
  "labelClass",
  "inputType",
  "readonly",
  "locale",
]);

const emits = defineEmits(["update:modelValue", "input-change"]);

const inputValue = ref(props.modelValue ?? props.inputValue);
const error = ref("");

watch(
  () => props.modelValue ?? props.inputValue,
  (value) => {
    if (value !== inputValue.value) {
      inputValue.value = value;
    }
  }
);

const decimalSeparator = computed(() => {
  return new Intl.NumberFormat(props.locale || "pt-PT").format(1.1).charAt(1);
});

const formattedValue = computed(() => {
  const value = props.modelValue?.toString() || "";
  return value.replace(".", decimalSeparator.value);
});

const computedInputClass = computed(() => {
  let paddingY = props.small ? "py-1" : "py-2";
  let classes = `block w-full border-gray-300 pl-4 pr-4 focus:border-${props.color}-500 focus:ring-${props.color}-500 text-sm ${paddingY}`;
  classes = `${classes} ${props.inputClass}`;
  return classes;
});

const getInputType = computed(() => {
  return props.inputType ? props.inputType : "text";
});

const onInput = (event) => {
  const rawValue = event.target.value;
  const normalizedValue = rawValue.replace(decimalSeparator.value, ".");

  if (isValidDecimal(normalizedValue)) {
    error.value = "";
    inputValue.value = normalizedValue;
    emits("update:modelValue", parseFloat(normalizedValue));
  } else {
    error.value = "";
    inputValue.value = rawValue;
    emits("update:modelValue", rawValue);
  }
};

const emitChange = () => {
  emits("input-change");
};

function isValidDecimal(value) {
  return /^\d+(\.\d+)?$/.test(value);
}
</script>

<style scoped>
.error-message {
  color: red;
  font-size: 0.875em;
}
</style>
