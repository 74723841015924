<template>
  <div>
    <label v-if="label" class="block text-sm font-medium text-gray-700">
      <T :keyName="label" />
    </label>
    <div :class="outerClass">
      <input
        type="text"
        :placeholder="placeholder"
        class="block w-full border-gray-300 pr-12 focus:border-gray-500 focus:ring-gray-500 focus:ring-0 text-sm rounded-full shadow-sm py-1.5"
        :value="formattedInputValue"
        @input="onInput"
      />
      <div class="absolute inset-y-0 right-0 flex items-center">
        <label for="options" class="sr-only">Unit</label>
        <select
          :value="selectValue"
          @change="selectChanged"
          id="options"
          name="options"
          class="h-full border-transparent bg-transparent py-0 pl-2 pr-6 text-gray-500 focus:border-gray-500 focus:ring-gray-500 focus:ring-0 text-sm"
        >
          <option v-for="[key, value] in options.entries()" :value="key">
            {{ value.text }}
          </option>
        </select>
      </div>
    </div>
    <p v-if="error" class="error-message">{{ error }}</p>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";

const props = defineProps(["label", "inputValue", "selectValue", "options", "callback", "placeholder", "locale"]);
const emits = defineEmits(["input-changed", "select-changed"]);

const inputValue = ref(props.inputValue);
const error = ref("");

const decimalSeparator = computed(() => {
  return new Intl.NumberFormat(props.locale || "pt-PT").format(1.1).charAt(1);
});

watch(
  () => props.modelValue,
  (value) => {
    if (value !== inputValue.value) {
      inputValue.value = value;
    }
  }
);

const formattedInputValue = computed(() => {
  const value = inputValue.value?.toString() || "";
  return value.replace(".", decimalSeparator.value);
});

const onInput = (event) => {
  const rawValue = event.target.value;
  const normalizedValue = rawValue.replace(decimalSeparator.value, ".");

  if (isValidDecimal(normalizedValue)) {
    error.value = "";
    inputValue.value = normalizedValue;
    emits("input-changed", parseFloat(normalizedValue));
  } else {
    error.value = "";
    inputValue.value = rawValue;
    emits("input-changed", rawValue);
  }
};

const selectChanged = (event) => {
  emits("select-changed", event.target.value);
};

const outerClass = computed(() => {
  return `${props.label ? "mt-1" : ""} relative rounded-md`;
});

function isValidDecimal(value) {
  return /^\d+(\.\d+)?$/.test(value);
}
</script>

<style scoped>
.error-message {
  color: red;
  font-size: 0.875em;
}
</style>
